import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CourseProgressService } from 'src/app/services/course-progress.service';
import {
  BackendService
} from 'src/app/services/backend.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserModel } from 'src/app/models/backend.model';

@Component({
  selector: 'app-course-progress',
  templateUrl: './course-progress.component.html'
})
export class CourseProgressComponent implements OnInit {
  total$: Observable<number>;
  completed$: Observable<number>;
  showProgress = false;
  name = '';
  disabled = true;
  status = true
  courseOrderStatus: string | undefined;
  constructor(
    private readonly router: Router,
    private readonly courseProgress: CourseProgressService,
    private readonly backendService: BackendService,
    private readonly dialog: MatDialog
  ) {
    this.total$ = this.courseProgress.total$;
    this.completed$ = this.courseProgress.completed$;

    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (e.url.startsWith('/course')) {
          this.name = e.url.split('/')[2]
          this.backendService.getCourseStatus(this.name).then(res => {
            this.courseOrderStatus = res.result?.status;
            if (this.courseOrderStatus == 'paid') {
              this.showProgress = true
            } else {
              this.showProgress = false;
            }
            this.backendService.getProgressCourse(this.name).then(course => {

              this.courseProgress.setTotal(course.result.total)
              this.courseProgress.setCompleted(course.result.completed)

            }).catch(e => {
              this.showProgress = false;
            });
          })
        } else {
          this.showProgress = false;
        }
      }
    })
  }
  getCertificate() {
    this.backendService.isCertificate(this.name).then(res => {
      this.status = res.status
      if (this.status) {
        this.router.navigate(['/certificate/' + this.name])
      } else {
        this.dialog.open(UserNameDialogComponent, {
          data: {
            courseName: this.name
          },
          maxWidth: '700px',
        })
      }
    })
  }
  ngOnInit(): void {
  }

}

@Component({
  selector: 'app-user-name-dialog',
  templateUrl: `./user-name-dialog.component.html`
})
export class UserNameDialogComponent {
  name: string;
  user: UserModel
  displayName = ''
  constructor(
    private readonly dialogRef: MatDialogRef<UserNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) readonly data: {
      courseName: string
    },
    private readonly backendService: BackendService,
  ) {
    this.name = data.courseName;

    this.backendService.userProfile().then(user => {
      this.user = user.result;
      this.displayName = this.user.displayName

    })
  }
}
