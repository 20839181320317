<mat-dialog-content>
    <div class="flex flex-col gap-5 max-w-xs">
      <div>
        <h3 class="mb-2 font-medium">Сертификат дээр хэвлэгдэх нэр: </h3>  <p class = "font-semibold text-lg text-center">{{displayName}}</p>
      </div>
      <div>
        <button mat-raised-button color = "warn" class="px-6 font-semibold "  routerLink="/profile" [queryParams]="{tab: 'profile'}" [mat-dialog-close]="true">Нэр солих</button>
        <button mat-raised-button color = "primary" class="ml-4 px-6  font-semibold" [routerLink]="'/certificate/'+name" [mat-dialog-close]="true"  >Нэр солихгүй</button>
      </div>
    </div>
  </mat-dialog-content>
  