import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DisplayWidthService {
    private innerWidth = new BehaviorSubject<number>(0);

    constructor(@Inject(PLATFORM_ID) private platformId: Object) {
        this.checkWidth();
    }

    innerWidth$: Observable<number> = this.innerWidth;

    checkWidth() {
        if (isPlatformBrowser(this.platformId)) {
            this.innerWidth.next(window.innerWidth);
        }
    }
}