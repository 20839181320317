import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Params,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faApple,
  faFacebook,
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faTwitter,
  faYoutube,
  faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons';
import {
  faAt,
  faGlobe,
  faVideoCamera,
  faTrophy,
  faStar,
  faPlay,
  faCrop,
} from '@fortawesome/free-solid-svg-icons';
import { faStarHalfStroke } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarR } from '@fortawesome/free-regular-svg-icons';

import { FacebookService, InitParams } from 'ngx-facebook';
import { AuthService, AuthTokenModel } from './auth/auth.service';
import { TitleService } from './services/title.service';
import { UpdateService } from './services/update.service';
import { DisplayWidthService } from './services/display-width.service';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  loadingRouteConfig: boolean;
  isDarkTheme = false;
  isBrowser = false;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly fb: FacebookService,
    private readonly titleService: TitleService,
    private readonly library: FaIconLibrary,
    private readonly updateService: UpdateService,
    private readonly displayWidthService: DisplayWidthService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.route.queryParamMap.subscribe((map) => {
      if (map.has('token')) {
        try {
          const token: AuthTokenModel = JSON.parse(
            decodeURIComponent(map.get('token'))
          );
          this.authService.appleLogin(token);
        } catch (e) {
          console.error('json parse not working');
        }
      }
    });

    this.updateService.checkForUpdates();
    this.library.addIcons(
      faFacebook,
      faFacebookSquare,
      faYoutubeSquare,
      faYoutube,
      faInstagram,
      faLinkedin,
      faTwitter,
      faApple,
      faAt,
      faGlobe,
      faVideoCamera,
      faTrophy,
      faStar,
      faStarHalfStroke,
      faStarR,
      faPlay,
      faCrop
    );

    this.authService.autoAuthUser();

    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
      this.initFacebookService();
    }

    // Router loading
    this.router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRouteConfig = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRouteConfig = false;
      } else if (event instanceof NavigationEnd) {
        this.titleService.setTitle('');
        if (isPlatformBrowser(this.platformId)) {
          gtag('config', 'G-WEFVSHCHQG', {
            page_path: event.urlAfterRedirects,
          });
        }
      }
    });
  }

  initFacebookService() {
    const initParams: InitParams = {
      appId: '265898481814734',
      xfbml: true,
      version: 'v14.0',
    };
    this.fb.init(initParams);
  }

  ngOnInit() {
    this.isDarkTheme = localStorage.getItem('theme') === 'dark';
  }

  setDarkTheme(isDarkTheme: boolean) {
    this.isDarkTheme = isDarkTheme;
    localStorage.setItem('theme', this.isDarkTheme ? 'dark' : 'light');
  }
  onResize() {
    this.displayWidthService.checkWidth();
  }
}
