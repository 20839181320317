
<!-- {{showProgress}}  -->
<div *ngIf="showProgress" class="flex h-full mr-3 cursor-pointer"  [matMenuTriggerFor]="menu">
    <div class="-translate-x-2.5 -translate-y-0.125">
      <mat-spinner class="inline" 
          mode="determinate" 
          color="accent"
          diameter=42
        value="100">
      </mat-spinner>
      <mat-spinner color="warn" diameter=42  mode="determinate" class="inline"  [value]="(completed$ | async)*100/(total$ | async)"></mat-spinner>
    </div>
    <div class="flex flex-row items-center  pt-1.5">
      <fa-icon [icon]="['fas', 'trophy']" ></fa-icon>
    </div>  
  </div> 
  <mat-menu #menu="matMenu" class="mt-2">
    <a class="font-semibold m-4" >{{(total$ | async)}} сэдвээс {{(completed$ | async)}} дууссан</a> <br>
    <button  mat-raised-button color="warn"  (click)="getCertificate()" class=" rounded-none text-white font-extrabold px-12 m-4" *ngIf="(completed$ | async) === (total$ | async); else disable" >Сертификат авах</button>
    <ng-template #disable>
       <button  mat-raised-button disabled color="warn" class=" rounded-none text-white font-extrabold px-12 m-4" >Сертификат авах</button>
    </ng-template>
  </mat-menu>
  <!-- [routerLink]="'/certificate/'+name" -->