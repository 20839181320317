import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
  { path: 'explore', loadChildren: () => import('./pages/explore/explore.module').then(m => m.ExploreModule) },
  { path: 'auth/login', loadChildren: () => import('./auth/login/login.module').then(m => m.LoginModule) },
  { path: 'auth/register', loadChildren: () => import('./auth/register/register.module').then(m => m.RegisterModule) },
  { path: 'teacher', loadChildren: () => import('./pages/teacher/teacher.module').then(m => m.TeacherModule) },
  { path: 'course', loadChildren: () => import('./pages/course/course.module').then(m => m.CourseModule) },
  { path: 'courses', loadChildren: () => import('./pages/course-list/course-list.module').then(m => m.CourseListModule) },
  { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule) },
  { path: 'paths', loadChildren: () => import('./pages/path-list/path-list.module').then(m => m.PathListModule) },
  { path: 'path', loadChildren: () => import('./pages/path/path.module').then(m => m.PathModule) },
  { path: 'privacy', loadChildren: () => import('./pages/privacy/privacy.module').then(m => m.PrivacyModule) },
  { path: 'admin', loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard], data: { level: 7 } },
  { path: 'teacher-profile', loadChildren: () => import('./pages/teacher-profile/teacher-profile.module').then(m => m.TeacherProfileModule), canActivate: [AuthGuard], data: { level: 3 } },
  { path: 'notifications', loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsModule) },
  { path: 'certificate', loadChildren: () => import('./pages/certificate/certificate.module').then(m => m.CertificateModule) },
  { path: 'v', loadChildren: () => import('./pages/verify/verify.module').then(m => m.VerifyModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', initialNavigation: 'enabledBlocking', scrollPositionRestoration:'enabled' })],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
