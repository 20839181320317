import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private primary = '';
  private secondary = '';
  private base = 'Port Education - Онлайн сургалт';

  constructor(private readonly titleService: Title) { }

  setTitle(primary: string, secondary: string = '') {
    this.primary = primary;
    this.secondary = secondary;

    this.titleService.setTitle([this.base, this.primary, this.secondary].filter(x => x).join(' - '))
  }
}
