import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth.service';
declare const AppleID: any;

const CLIENT_ID = "mn.port.appleauth"
const REDIRECT_API_URL = "https://backend.port.mn/api/user/apple"

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
})
export class LoginDialogComponent implements OnInit, OnDestroy {
  private authStatusSub: Subscription;
  isLoading = false;
  isLoadingLogin = false;

  constructor(private readonly authService: AuthService,
    private readonly snackBar: MatSnackBar,
    private readonly dialogRef: MatDialogRef<LoginDialogComponent>,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      AppleID.auth.init({
        clientId: CLIENT_ID,
        scope: 'name email',
        redirectURI: REDIRECT_API_URL,
        state: '',
      });
    }

    this.authStatusSub = this.authService.getAuthStatusListener().subscribe(
      authStatus => {
        this.isLoading = false;
        if (authStatus) {
          this.dialogRef.close();
          this.snackBar.open('Та амжилттай нэвтрэн орлоо', 'ОК', {
            duration: 3000
          });
        }
      }
    );
  }

  async onFacebookLogin() {
    try {
      await this.authService.facebookLogin();
      this.isLoadingLogin = true
    } catch (e) {
      this.snackBar.open('Facebook алдаа гарлаа', 'ОК', {
        duration: 3000
      });
    }
  }

  async onAppleLogin() {
    if (isPlatformBrowser(this.platformId)) {
      AppleID.auth.signIn();
    }
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }
}
