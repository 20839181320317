import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        this.authService.autoAuthUser();

        const isAuth = this.authService.getIsAuth();

        // If not authenticated
        if (!isAuth) {
            this.router.navigate(['/auth/login']);
            return false;
        }

        // If privileged
        if (this.authService.getLevel() >= route.data.level) {
            return true;
        }
        this.router.navigate(['/']);
        return false;
    }
}
