import {
  HttpClient
} from '@angular/common/http';
import {
  Injectable
} from '@angular/core';
import {
  MatSnackBar
} from '@angular/material/snack-bar';
import {
  Router
} from '@angular/router';
import {
  FacebookService,
  LoginResponse
} from 'ngx-facebook';
import {
  BehaviorSubject,
  Observable
} from 'rxjs';
import {
  environment
} from 'src/environments/environment';
import { UserResponse } from '../models/backend.model';
import {
  BackendService
} from '../services/backend.service';
import { NotificationService } from '../services/notification.service';

const BACKEND_URL = environment.backendUrl + 'api/user';

export class AuthTokenModel {
  token: string;
  expiresIn: number;
  userId: number;
  level: number;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authStatusListener = new BehaviorSubject < boolean > (false);
  private authLevelListener = new BehaviorSubject < number > (0);

  private token: string;
  private userId: number;
  private level: number;
  private isAuthenticated: boolean;
  private tokenTimer: any;

  constructor(
    private readonly http: HttpClient,
    private readonly backendService: BackendService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly fb: FacebookService,
    private readonly notificationService: NotificationService,
  ) {}

  getIsAuth() {
    return this.isAuthenticated;
  }

  getToken() {
    return this.token;
  }

  getAuthStatusListener(): Observable < boolean > {
    return this.authStatusListener;
  }

  getAuthLevelListener(): Observable < number > {
    return this.authLevelListener;
  }

  getUserId() {
    return this.userId;
  }

  getLevel(): number {
    return this.level;
  }

  login(email: string, password: string) {
    this.http.post < AuthTokenModel > (BACKEND_URL + '/login', {
        email,
        password
      })
      .subscribe((res) => {
        this.loginWithToken(res);
        this.router.navigate(['/']);
      }, () => {
        this.authStatusListener.next(false);
        this.authLevelListener.next(0);
      });
  }

  register(email: string, password: string) {
    this.http.post < AuthTokenModel > (BACKEND_URL + '/signup', {
        email,
        password
      })
      .subscribe((res) => {
        this.loginWithToken(res);
        this.router.navigate(['/']);
      }, () => {
        this.authStatusListener.next(false);
        this.authLevelListener.next(0);
      });
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.token = authInformation.token;
      this.isAuthenticated = true;
      this.level = authInformation.level;
      this.userId = parseInt(authInformation.userId, 10);
      this.setAuthTimer(expiresIn / 1000);
      this.authStatusListener.next(true);
      this.authLevelListener.next(this.level);
    }
  }

  logout() {
    this.token = null;
    this.isAuthenticated = false;
    this.userId = null;
    this.authStatusListener.next(false);
    this.authLevelListener.next(0);
    clearTimeout(this.tokenTimer);
    this.clearAuthData();
    this.router.navigate(['/']);
  }

  facebookLogin() {
    this.fb.login().then((response: LoginResponse) => {
      if (response.authResponse) {
        return this.http.post < any > (BACKEND_URL + '/facebook', {
          access_token: response.authResponse.accessToken,
        }).subscribe((res) => {
          this.loginWithToken(res);

        }, error => {
          this.authStatusListener.next(false);
        });
      }
    }).catch(e => console.log(e));
  }

  appleLogin(token: AuthTokenModel) {
    this.loginWithToken(token);
  }

  private loginWithToken(authToken: AuthTokenModel) {
    this.token = authToken.token;
    if (this.token) {
      const expiresInDuration = authToken.expiresIn;
      this.setAuthTimer(expiresInDuration);
      this.isAuthenticated = true;
      this.userId = authToken.userId;
      this.level = authToken.level;
      this.authStatusListener.next(true);
      this.authLevelListener.next(this.level);
      const now = new Date();
      const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
      this.backendService.listNotificationsFromUserId().then(res => {
        this.notificationService.setNotifications(res.result);
      });
      this.saveAuthData(this.token, expirationDate, this.userId, this.level);
      this.snackBar.open('Амжилттай нэвтэрлээ!', 'Okay', {
        duration: 3000
      });

    }
  }

  private setAuthTimer(duration: number) {
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration * 1000);
  }

  private getAuthData() {
    const token = localStorage.getItem('token');
    const expirationDate = localStorage.getItem('expiration');
    const userId = localStorage.getItem('userId');
    const level = localStorage.getItem('level');


    if (!token || !expirationDate || !userId || !level) {
      return;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate),
      userId: userId,
      level: parseInt(level, 10),
    };
  }

  private saveAuthData(token: string, expirationDate: Date, userId: number, level: number) {
    localStorage.setItem('token', token);
    localStorage.setItem('userId', userId.toString());
    localStorage.setItem('expiration', expirationDate.toISOString());
    localStorage.setItem('level', level.toString());
  }

  private clearAuthData() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('expiration');
    localStorage.removeItem('level');
  }

  deleteUser(){
    return this.http.delete<UserResponse>(BACKEND_URL + '/delete').toPromise();
  }
}
