import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  MatDialog
} from '@angular/material/dialog';
import {
  Subject, Observable
} from 'rxjs';
import {
  takeUntil
} from 'rxjs/operators';
import {
  AuthService
} from 'src/app/auth/auth.service';
import {
  LoginDialogComponent
} from 'src/app/auth/login-dialog/login-dialog.component';
import {
  NotificationModel
} from 'src/app/models/backend.model';
import {
  AnalyticsService
} from 'src/app/services/analytics.service';
import {
  BackendService
} from 'src/app/services/backend.service';
import {
  environment
} from 'src/environments/environment';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  isDarkTheme = false;
  hidden = false;
  unreadCount$: Observable<number>;
  notifications$: Observable<NotificationModel[]>;

  private readonly destroy$ = new Subject<void>();
  level = 0;
  @Output() sidenavToggle = new EventEmitter<void>();
  @Output() darkThemeToggle = new EventEmitter<boolean>();
  userIsAuthenticated = false;


  backendUrl = environment.backendUrl;

  constructor(
    private readonly backendService: BackendService,
    private readonly authService: AuthService,
    private readonly dialog: MatDialog,
    private readonly analytics: AnalyticsService,
    private readonly notificationService: NotificationService,
  ) {
    this.notifications$ = this.notificationService.notifications$;
    this.unreadCount$ = this.notificationService.unreadCount$;

    this.userIsAuthenticated = this.authService.getIsAuth();

    this.authService.getAuthStatusListener().pipe(takeUntil(this.destroy$)).subscribe(
      isAuthenticated => this.userIsAuthenticated = isAuthenticated
    );
    this.authService.getAuthLevelListener().pipe(takeUntil(this.destroy$)).subscribe(
      level => this.level = level,
    );
    if (this.level > 0) {
      this.backendService.listNotificationsFromUserId().then(res => {
        this.notificationService.setNotifications(res.result);
        // this.notifications = res.result
        // for ( let i = 0; i < this.notifications.length; i++){
        //   if(this.notifications[i].status === true){
        //     this.unreadNotifications = this.unreadNotifications + 1
        //   }
        // }
        // if(this.unreadNotifications === 0){
        //   this.hidden = !this.hidden;
        // }
      })
    }
  }

  getPicture(profilePic: string) {
    if (profilePic.startsWith('https://')) {
      return profilePic;
    }
    return this.backendUrl + profilePic
  }

  openLoginDialog() {
    this.analytics.eventEmitter('login_dialog', 'button', 'click')
    this.dialog.open(LoginDialogComponent, {
      width: '300px',
      panelClass: 'dialog-custom',
    });
  }

  statusChange(id: number, i: number, body) {
    this.notificationService.decreaseUnreadCount();
    this.notificationService.setNotificationStatus(i, false);
    this.backendService.statusChangeNotification(id, body).then().catch(e => {
      this.notificationService.increaseUnreadCount();
    })
  }

  notificationMenu() {
    this.hidden = true;
  }

  storeCurrentTheme() {
    this.darkThemeToggle.emit(this.isDarkTheme);
  }

  logout() {
    this.authService.logout();
  }

  onToggleSideNav() {
    this.sidenavToggle.emit();
  }

  getNotificationText(text: string) {
    if (text.length > 16) {
      return text.substring(0, 16) + '  ...';
    } else {
      return text
    }
  }

  ngOnInit(): void {
    this.isDarkTheme = localStorage.getItem('theme') === 'dark';
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


}
