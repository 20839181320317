<footer class="bg-[#707070] text-white text-sm">
  <div class="container pt-5 pb-3">
    <div class="flex justify-between">
      <div></div>

      <div>
        <div class="text-right">
          Утас: 11-313363
          <br />
          99098478
        </div>

        <br />

        <div class="text-right">
          Хаяг: Blue Sky Tower - 409
          <br />
          Сүхбаатар дүүрэг, 1-р хороо
        </div>
      </div>
    </div>
    <br />
    <div class="flex justify-between">
      <div class="flex items-center">
        <img class="h-9 w-9" alt="port logo" src="assets/logo/port.png" />
        <div class="ml-2">2023 Port.mn</div>
      </div>
      <div>
        <div class="text-right">
          <a
            href="https://www.instagram.com/port.mn/"
            target="_blank"
            aria-label="Instagram"
            rel="noreferrer"
          >
            <fa-icon class="mr-2 fa-lg" [icon]="['fab', 'instagram']">
            </fa-icon>
          </a>
          <a
            href="https://www.youtube.com/channel/UCY2qjje8G2i43Gf4k2KwkwA"
            target="_blank"
            aria-label="Youtube"
            rel="noreferrer"
          >
            <fa-icon class="mr-2 fa-lg" [icon]="['fab', 'youtube']"> </fa-icon>
          </a>

          <a
            href="https://www.facebook.com/port.mongolia"
            target="_blank"
            aria-label="Facebook"
            rel="noreferrer"
          >
            <fa-icon class="mr-2 fa-lg" [icon]="['fab', 'facebook-square']">
            </fa-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</footer>
