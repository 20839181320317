<div [hidden]="!isBrowser">
  <app-header></app-header>

  <div class="flex flex-col justify-between h-screen  ">
    <div>
      <main (window:resize)="onResize()" [hidden]="loadingRouteConfig">
        <router-outlet></router-outlet>
      </main>
      <ng-container *ngIf="loadingRouteConfig">
        <app-spinner></app-spinner>
      </ng-container>
    </div>
    <app-footer></app-footer>
  </div>
</div>
<ng-container *ngIf="!isBrowser">
  <app-spinner></app-spinner>
</ng-container>