import { Component, Inject, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './error.component.html'
})
export class ErrorComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: { message: string }) { }
    message = 'An unknown error occurred!';
}

@NgModule({
    declarations: [ErrorComponent],
    imports: [
        MatButtonModule,
        MatDialogModule
    ],
})
export class ErrorModule { }
