import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CourseProgressService {
    private total = new BehaviorSubject<number>(-1);
    private completed = new BehaviorSubject<number>(0);

    total$: Observable<number> = this.total;
    completed$: Observable<number> = this.completed;
    getTotal(){
      return this.total.getValue()  
    }

    getCompleted(){
        return this.completed.getValue()  
    }

    setTotal(total: number) {
        this.total.next(total);
    }

    setCompleted(completed: number) {
        this.completed.next(completed);
    }

    increaseCompleted() {
        this.completed.next(this.completed.value + 1)
    }

    decreaseCompleted() {
        this.completed.next(this.completed.value - 1)
    }
}
