import { Injectable } from '@angular/core';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() { }

  eventEmitter(
    eventName: string,
    event_category: string,
    event_action: string,
    event_label: string = null,
    event_value: number = null) {
    gtag('event', eventName, {
      event_category,
      event_label,
      event_action,
      event_value,
    })
  }
}
