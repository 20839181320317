<div class="text-white flex flex-col justify-center items-center">
    <h1 class="mb-4">
        Нэвтрэх
    </h1>

    <button mat-raised-button [disabled]="isLoadingLogin"
        (click)="onFacebookLogin()"
        class="fb-button w-full text-left mb-4">
        <fa-icon class="mr-2"
            [icon]="['fab', 'facebook-square']" size="2xl">
        </fa-icon>
        Sign in with Facebook
    </button>

    <button mat-raised-button [disabled]="isLoadingLogin"
        (click)="onAppleLogin()"
        class="apple-button w-full text-left">
        <fa-icon class="mr-2"
            [icon]="['fab', 'apple']" size="2xl">
        </fa-icon>
        Sign in with Apple
    </button>
</div>