import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {NotificationModel} from '../models/backend.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
    private notificationsSubject = new BehaviorSubject<NotificationModel[]>([])
    private unreadCountSubject = new BehaviorSubject<number>(0)

    notifications$: Observable<NotificationModel[]> = this.notificationsSubject;
    unreadCount$: Observable<number> = this.unreadCountSubject;

    setNotifications(notifications: NotificationModel[]) {
      let unreadCount = 0;
      for ( let i = 0; i < notifications.length; i++){
        if(notifications[i].status === true){
          unreadCount = unreadCount + 1
        }
      }

      this.unreadCountSubject.next(unreadCount);
      this.notificationsSubject.next(notifications);
    }

    setNotificationStatus(i:number, status: boolean) {
      const notifications = this.notificationsSubject.value;
      notifications[i].status = status;
      this.notificationsSubject.next(notifications);
    }

    decreaseUnreadCount() {
      this.unreadCountSubject.next(Math.max(0, this.unreadCountSubject.value - 1));
    }

    increaseUnreadCount() {
      this.unreadCountSubject.next(this.unreadCountSubject.value + 1);
    }
}
