<mat-toolbar class="flex justify-between"
  color="primary">
  <div class="flex items-center">
    <a routerLink="/">
      <img class="logo rounded-lg h-12 w-12	align-items: center;"
        alt="port.mn logo"
        src="assets/logo/port.png">
    </a>
    <button mat-button
      routerLink="/">Port.mn
    </button>
  </div>
    
  <div class="flex place-items-center gap-0.5">
    <app-course-progress ></app-course-progress>
    <button *ngIf="!userIsAuthenticated"
      mat-raised-button
      color="accent"
      (click)="openLoginDialog()">
      Нэвтрэх
    </button>
    
    <button *ngIf="userIsAuthenticated"
      color="white"
      aria-label="Favorites"
      mat-icon-button
      routerLink="/profile"
      [queryParams]="{tab: 'like'}">
      <mat-icon>favorite_border</mat-icon>
    </button>
    <mat-icon *ngIf="userIsAuthenticated"
      (click)="notificationMenu()"
      matBadgeColor="warn"
      aria-label="Notifications"
      mat-icon-button
      [matMenuTriggerFor]="notification"
      [matBadge]="unreadCount$ | async"
      [matBadgeHidden]="hidden || ((unreadCount$ | async) === 0)">
        notifications
    </mat-icon>
    <button *ngIf="userIsAuthenticated"
      [matMenuTriggerFor]="menu"
      color="white"
      aria-label="My Account"
      mat-icon-button>
      <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #notification="matMenu">
      <h3 class="ml-2.5">Notification</h3>
      <div *ngIf="(notifications$ | async).length === 0"
        class="w-62.5 h-15">
        Мэдэгдэл ирээгүй байна
      </div>
      <div *ngIf="(notifications$ | async) as notifications">
        <div *ngIf="notifications.length > 0">
          <div class="flex "
            mat-menu-item
            *ngFor="let notification of notifications, let i = index"
            routerLink="{{notification.link}}">
            <div matTooltipPosition="above"
              [matTooltip]="notification.text"
              *ngIf="notification.status === false"
              class="flex">
              <img class="rounded-full w-10 h-10 mr-2"
                [src]="getPicture(notification.fromUser.profilePic)">
              <span>
                {{getNotificationText(notification.text)}}
              </span>
            </div>
            <div matTooltipPosition="above"
              [matTooltip]="notification.text"
              *ngIf="notification.status === true"
              class="flex"
              (click)="statusChange(notification.id, i)">
              <img class="rounded-full w-10 h-10 mr-2"
                [src]="getPicture(notification.fromUser.profilePic)">
              <span>
                <b>
                  {{getNotificationText(notification.text)}}
                </b>
              </span>
              <b>
                •
              </b>
            </div>
          </div>
          <button class="flex justify-center"
            mat-menu-item
            routerLink="/notifications">
            All Notifications
          </button>
        </div>
      </div>

    </mat-menu>
    <mat-menu #menu="matMenu">
      <button mat-menu-item
        routerLink="/profile"
        [queryParams]="{tab: 'profile'}">
        Миний булан
      </button>
      <button mat-menu-item
        routerLink="/profile"
        [queryParams]="{tab: 'mine'}">
        Миний хичээлүүд
      </button>
      <button mat-menu-item
        routerLink="/profile"
        [queryParams]="{tab: 'like'}">
        Дуртай хичээлүүд
      </button>
      <button mat-menu-item
        routerLink="/profile/order">
        Захиалгууд
      </button>
      <button *ngIf="level >= 7"
        mat-menu-item
        routerLink="/admin">
        Admin
      </button>
      <button *ngIf="level >= 3"
        mat-menu-item
        routerLink="/teacher-profile">
        Teacher Profile
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item
        routerLink="/"
        (click)="logout()">
        Гарах
      </button>
    </mat-menu>
  </div>
</mat-toolbar>