import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorComponent } from './error.component';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private dialog: MatDialog,
        @Inject(PLATFORM_ID) private platformId: Object,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                let errorMessage = 'An unknown error occurred!';
                if (error.error.message) {
                    errorMessage = error.error.message;
                }

                if (error.error.code !== 'jwt malformed' && isPlatformBrowser(this.platformId)) {
                    this.dialog.open(ErrorComponent, { data: { message: errorMessage } });
                    return throwError(error);
                }
            })
        );
    }
}
