import {
  HttpClient
} from '@angular/common/http';
import {
  Injectable
} from '@angular/core';
import {
  environment
} from '../../environments/environment';
import {
  GetCourseResponse,
  GetLectureResponse,
  GetTeacherResponse,
  GetHomeResponse,
  ListCoursesResponse,
  ListCategoriesResponse,
  ListTeachersResponse,
  ListCommentsResponse,
  CommentModel,
  ListCourseOrdersResponse,
  GetPathResponse,
  FindCoursesResponse,
  TeacherUpdateModel,
  ListCouponResponse,
  findCouponResponse,
  CreateCouponModel,
  CreateCouponResponse,
  AdminListCoursesResponse,
  ListUsersResponse,
  UpdateCoursesResponse,
  CourseUpdateModel,
  AdminCourseResponse,
  CourseOrderModel,
  AdminListItemsResponse,
  AdminListItemResponse,
  AdminListItemModel,
  GetExamResponse,
  CreateExamModel,
  CreateExamResponse,
  CreateQuestionsResponse,
  CreateQuestionsModel,
  EditUserResponse,
  UpdateUserModel,
  UserResponse,
  GetQuestionsResponse,
  CourseItemModel,
  GetCourseItemResponse,
  UpdateQuestionResponse,
  UpdateQuestionModel,
  GetQuestionResponse,
  TeacherCreateExamResponse,
  TeacherCreateExamModel,
  TeacherCoursesResponse,
  UserProgressModel,
  GetUserProgressResponse,
  GetCourseProgressResponse,
  GetCertificateResponse,
  GetVerifyCertificateResponse,
  NotificationResponse,
  NotificationModel,
  CreateNotificationsResponse,
  CreateNotificationsModel,
  NewCommentModel,
  UserModel,
  GetCouponResponse,
  GetIsCertificateResponse,
  ReviewModel,
  ReviewResponse,
  courseReviewResponse
} from '../models/backend.model';

const BACKEND_URL = environment.backendUrl + 'api';

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  constructor(private readonly http: HttpClient) { }

  listCourseOrders(): Promise<ListCourseOrdersResponse> {
    return this.http.get<ListCourseOrdersResponse>(`${BACKEND_URL}/order`).toPromise();
  }

  listAllCourseOrders(): Promise<ListCourseOrdersResponse> {
    return this.http.get<ListCourseOrdersResponse>(`${BACKEND_URL}/admin/orders/all`).toPromise();
  }

  findCourseOrders(orderId: string): Promise<FindCoursesResponse> {
    return this.http.get<FindCoursesResponse>(`${BACKEND_URL}/admin/order/${orderId}`).toPromise();
  }

  findCourseId(id: number): Promise<AdminCourseResponse> {
    return this.http.get<AdminCourseResponse>(`${BACKEND_URL}/admin/course/${id}`).toPromise();
  }

  findItemsId(id: number): Promise<AdminListItemResponse> {
    return this.http.get<AdminListItemResponse>(`${BACKEND_URL}/admin/item/${id}`).toPromise();
  }

  updateCourseId(id: number, body: CourseUpdateModel): Promise<UpdateCoursesResponse> {
    return this.http.patch<UpdateCoursesResponse>(`${BACKEND_URL}/admin/course/${id}`, body).toPromise();
  }

  updateItemId(id: number, body: AdminListItemModel): Promise<AdminListItemResponse> {
    return this.http.patch<AdminListItemResponse>(`${BACKEND_URL}/admin/item/${id}`, body).toPromise();
  }

  getTeacherProfile(): Promise<GetTeacherResponse> {
    return this.http.get<GetTeacherResponse>(`${BACKEND_URL}/teacher-profile/profile`).toPromise();
  }

  patchTeacherProfile(body: TeacherUpdateModel): Promise<{
    message: string
  }> {
    return this.http.patch<{
      message: string
    }>(`${BACKEND_URL}/teacher-profile/profile`, body).toPromise();
  }

  listCourses(pageNumber?: number, tagId?: number): Promise<ListCoursesResponse> {
    if (pageNumber && tagId) {
      return this.http.get<ListCoursesResponse>(`${BACKEND_URL}/course/all?tag=${tagId}&pageNumber=${pageNumber}`).toPromise();
    }

    if (pageNumber) {
      return this.http.get<ListCoursesResponse>(`${BACKEND_URL}/course/all?pageNumber=${pageNumber}`).toPromise();
    }

    if (tagId) {
      return this.http.get<ListCoursesResponse>(`${BACKEND_URL}/course/all?tag=${tagId}`).toPromise();
    }
    return this.http.get<ListCoursesResponse>(`${BACKEND_URL}/course/all`).toPromise();
  }

  listOrders(pageNumber?: number): Promise<ListCourseOrdersResponse> {

    if (pageNumber) {
      return this.http.get<ListCourseOrdersResponse>(`${BACKEND_URL}/admin/orders/all?pageNumber=${pageNumber}`).toPromise();
    }
    return this.http.get<ListCourseOrdersResponse>(`${BACKEND_URL}/admin/orders/all`).toPromise();

  }

  listUsers(pageNumber = 0, fb_id = false): Promise<ListUsersResponse> {
    return this.http.get<ListUsersResponse>(`${BACKEND_URL}/admin/users/all?pageNumber=${pageNumber}&fb_id=${fb_id}`).toPromise();
  }


  listPaths(): Promise<ListCoursesResponse> {
    return this.http.get<ListCoursesResponse>(`${BACKEND_URL}/course/all?isPath=yes`).toPromise();
  }

  listMyCourses(): Promise<ListCoursesResponse> {
    return this.http.get<ListCoursesResponse>(`${BACKEND_URL}/course/mine`).toPromise();
  }

  listCategories(): Promise<ListCategoriesResponse> {
    return this.http.get<ListCategoriesResponse>(`${BACKEND_URL}/category/all`).toPromise();
  }

  listFavoriteCourses(): Promise<ListCoursesResponse> {
    return this.http.get<ListCoursesResponse>(`${BACKEND_URL}/favorite`).toPromise();
  }

  listTeachers(): Promise<ListTeachersResponse> {
    return this.http.get<ListTeachersResponse>(`${BACKEND_URL}/teacher`).toPromise();
  }

  getCourse(name: string, coupon?: string): Promise<GetCourseResponse> {
    return this.http.get<GetCourseResponse>(`${BACKEND_URL}/course/fetch/${name}?coupon=${coupon || ''}`).toPromise();
  }

  getPath(name: string, coupon?: string): Promise<GetPathResponse> {
    return this.http.get<GetPathResponse>(`${BACKEND_URL}/course/path/${name}?coupon=${coupon || ''}`).toPromise();
  }

  getTeacher(name: string): Promise<GetTeacherResponse> {
    return this.http.get<GetTeacherResponse>(`${BACKEND_URL}/teacher/${name}`).toPromise();
  }
  getLecture(name: string): Promise<GetLectureResponse> {
    return this.http.get<GetLectureResponse>(`${BACKEND_URL}/lecture/item/${name}`).toPromise();
  }

  getIOSLecture(name: string): Promise<GetLectureResponse> {
    return this.http.get<GetLectureResponse>(`${BACKEND_URL}/lecture/item-ios/${name}`, {
      withCredentials: true
    }).toPromise();
  }

  getHome(): Promise<GetHomeResponse> {
    return this.http.get<GetHomeResponse>(`${BACKEND_URL}/home`).toPromise();
  }

  addToFavorite(name: string): Promise<{
    message: string
  }> {
    return this.http.post<{
      message: string
    }>(`${BACKEND_URL}/favorite/${name}`, {}).toPromise();
  }

  getCourseStatus(name: string): Promise<{
    message: string; result: {
      status: string; orderId: string; price: number
    }
  }> {
    return this.http.get<{
      message: string; result: {
        status: string; orderId: string,
        price: number
      }
    }>(`${BACKEND_URL}/order/status/${name}`).toPromise();
  }

  validateOrder(courseName: string): Promise<{
    message: string; result: {
      status: string,
      price: number,
      orderId: string
    }
  }> {
    return this.http.post<{
      message: string; result: {
        status: string,
        price: number,
        orderId: string
      }
    }>(`${BACKEND_URL}/order/${courseName}`, {}).toPromise();
  }

  orderCourse(course: string, coupon?: string): Promise<{
    message: string; result: {
      orderId: string,
      price: number
    }
  }> {
    return this.http.post<{
      message: string; result: {
        orderId: string,
        price: number
      }
    }>(`${BACKEND_URL}/order`, {
      coupon,
      course
    }).toPromise();
  }

  orderBarimt(barimtType: string, price: number, orderId: string, email: string, registerNumber: number = 0) {
    return this.http.post<{
      message: string
    }>(`${BACKEND_URL}/ebarimt`, {
      barimtType,
      price,
      orderId,
      email,
      registerNumber
    }).toPromise();
  }

  replyListComment(name: string): Promise<ListCommentsResponse> {
    return this.http.get<ListCommentsResponse>(`${BACKEND_URL}/comment/reply/${name}`).toPromise();
  }

  listComments(name: string): Promise<ListCommentsResponse> {
    return this.http.get<ListCommentsResponse>(`${BACKEND_URL}/comment/all/${name}`).toPromise();
  }
  addComment(itemName: string, body: NewCommentModel): Promise<{ message: string; result: CommentModel }> {
    return this.http.post<{ message: string; result: CommentModel }>(`${BACKEND_URL}/comment/new/${itemName}`, body).toPromise();
  }
  addReply(itemName: string, body): Promise<{ message: string; result: CommentModel }> {
    return this.http.post<{ message: string; result: CommentModel }>(`${BACKEND_URL}/comment/reply/${itemName}`, body).toPromise();
  }
  saveComment(id: number, text: string): Promise<{ message: string; result: CommentModel }> {
    return this.http.patch<{ message: string; result: CommentModel }>(`${BACKEND_URL}/comment/edit/${id}`, { text }).toPromise();
  }

  deleteComment(id: number) {
    return this.http.delete<ListCouponResponse>(`${BACKEND_URL}/comment/comment/${id}`).toPromise();
  }


  updateCourseOrder(id: number, body: CourseOrderModel): Promise<FindCoursesResponse> {
    return this.http.patch<FindCoursesResponse>(`${BACKEND_URL}/admin/orders/${id}`, body).toPromise();
  }

  createCoupon(body: CreateCouponModel): Promise<CreateCouponResponse> {
    return this.http.post<CreateCouponResponse>(`${BACKEND_URL}/admin/coupon/new`, body).toPromise();
  }

  createTest(body: CourseItemModel): Promise<GetCourseItemResponse> {
    return this.http.post<GetCourseItemResponse>(`${BACKEND_URL}/admin/test/new`, body).toPromise();
  }

  saveProgress(body: UserProgressModel): Promise<GetUserProgressResponse> {
    return this.http.post<GetUserProgressResponse>(`${BACKEND_URL}/progress/save`, body).toPromise();
  }

  getProgressItem(): Promise<GetLectureResponse> {
    return this.http.get<GetLectureResponse>(`${BACKEND_URL}/progress/item/`).toPromise();
  }

  getProgressCourse(name: string): Promise<GetCourseProgressResponse> {
    return this.http.get<GetCourseProgressResponse>(`${BACKEND_URL}/progress/course/${name}`).toPromise();
  }

  getCertificate(name: string): Promise<GetCertificateResponse> {
    return this.http.get<GetCertificateResponse>(`${BACKEND_URL}/certificate/certificate/new/${name}`).toPromise();
  }

  getVerifyCertificate(id: string): Promise<GetVerifyCertificateResponse> {
    return this.http.get<GetVerifyCertificateResponse>(`${BACKEND_URL}/certificate/certificate/verify/${id}`).toPromise();
  }

  isCertificate(name: string): Promise<GetIsCertificateResponse> {
    return this.http.get<GetIsCertificateResponse>(`${BACKEND_URL}/certificate/certificate/c/${name}`).toPromise();
  }

  getCoupon(courseId: number, name: string) {
    return this.http.get<GetCouponResponse>(`${BACKEND_URL}/course/coupon/${courseId}/${name}`).toPromise();
  }

  updateCoupon(id: number, expireAt: string, percent: number): Promise<{
    message: string
  }> {
    return this.http.patch<{
      message: string
    }>(`${BACKEND_URL}/admin/coupon/update/${id}`, {
      expireAt,
      percent
    }).toPromise();
  }

  listCoupons(): Promise<ListCouponResponse> {
    return this.http.get<ListCouponResponse>(`${BACKEND_URL}/admin/coupon`).toPromise();
  }

  findCoupon(id: number): Promise<findCouponResponse> {
    return this.http.get<findCouponResponse>(`${BACKEND_URL}/admin/coupon/${id}`).toPromise();
  }
  deleteCoupon(id: number): Promise<ListCouponResponse> {
    return this.http.delete<findCouponResponse>(`${BACKEND_URL}/admin/coupon/${id}`).toPromise();
  }

  adminListCourses(): Promise<AdminListCoursesResponse> {
    return this.http.get<AdminListCoursesResponse>(`${BACKEND_URL}/admin/courses/all`).toPromise();
  }

  adminListItems(courseId?: number, pageNumber?: number,): Promise<AdminListItemsResponse> {
    return this.http.get<AdminListItemsResponse>(`${BACKEND_URL}/admin/items/all?courseId=${courseId ?? 0}&pageNumber=${pageNumber ?? 0}`).toPromise();
  }

  adminListUsers(): Promise<ListUsersResponse> {
    return this.http.get<ListUsersResponse>(`${BACKEND_URL}/admin/users/all`).toPromise();
  }

  adminEditUser(id: number, body: UpdateUserModel): Promise<EditUserResponse> {
    return this.http.patch<EditUserResponse>(`${BACKEND_URL}/admin/user/${id}`, body).toPromise();
  }

  adminFindUser(id: number): Promise<UserResponse> {
    return this.http.get<UserResponse>(`${BACKEND_URL}/admin/user/${id}`).toPromise();
  }


  getExam(id: number): Promise<GetExamResponse> {
    return this.http.get<GetExamResponse>(`${BACKEND_URL}/exam/fetch/${id}`).toPromise();
  }


  listExam(): Promise<GetExamResponse> {
    return this.http.get<GetExamResponse>(`${BACKEND_URL}/exam/all`).toPromise();
  }

  createExam(body: CreateExamModel): Promise<CreateExamResponse> {
    return this.http.post<CreateExamResponse>(`${BACKEND_URL}/exam/create`, body).toPromise();
  }

  listTeacherExams(): Promise<GetExamResponse> {
    return this.http.get<GetExamResponse>(`${BACKEND_URL}/exam/teacher-exams`).toPromise();
  }
  listTeacherCourse(): Promise<AdminListCoursesResponse> {
    return this.http.get<AdminListCoursesResponse>(`${BACKEND_URL}/course/teacher/all`).toPromise();
  }

  createTeacherExam(body: TeacherCreateExamModel): Promise<TeacherCreateExamResponse> {
    return this.http.post<TeacherCreateExamResponse>(`${BACKEND_URL}/exam/teacher-exam/create`, body).toPromise();
  }

  getQuestionExamId(id: number): Promise<GetQuestionsResponse> {
    return this.http.get<GetQuestionsResponse>(`${BACKEND_URL}/questions/fetch/${id}`).toPromise();
  }

  getQuestion(id: number): Promise<GetQuestionResponse> {
    return this.http.get<GetQuestionResponse>(`${BACKEND_URL}/questions/question/${id}`).toPromise();
  }
  createQuestions(body: CreateQuestionsModel): Promise<CreateQuestionsResponse> {
    return this.http.post<CreateQuestionsResponse>(`${BACKEND_URL}/questions/create`, body).toPromise();
  }

  updateQuestions(id: number, body: UpdateQuestionModel): Promise<UpdateQuestionResponse> {
    return this.http.patch<UpdateQuestionResponse>(`${BACKEND_URL}/questions/update/${id}`, body).toPromise();
  }

  findChapterNames(CourseId: number): Promise<AdminListItemsResponse> {
    return this.http.get<AdminListItemsResponse>(`${BACKEND_URL}/admin/chapterNames/${CourseId}`).toPromise();
  }

  findCourse(): Promise<TeacherCoursesResponse> {
    return this.http.get<TeacherCoursesResponse>(`${BACKEND_URL}/teacher-profile/findCourse`).toPromise();
  }
  findCourseUserId(id: number): Promise<AdminListCoursesResponse> {
    return this.http.get<AdminListCoursesResponse>(`${BACKEND_URL}/admin/course-userId/${id}`).toPromise();

  }

  listNotificationsFromUserId(): Promise<NotificationResponse> {
    return this.http.get<NotificationResponse>(`${BACKEND_URL}/notifications/fromUser`).toPromise();
  }

  listNotifications(userId: number): Promise<NotificationResponse> {
    return this.http.get<NotificationResponse>(`${BACKEND_URL}/notifications/user/${userId}`).toPromise();
  }

  allNotificationsUser(): Promise<NotificationResponse> {
    return this.http.get<NotificationResponse>(`${BACKEND_URL}/notifications/all`).toPromise();
  }

  statusChangeNotification(id: number, body: NotificationModel): Promise<NotificationResponse> {
    return this.http.patch<NotificationResponse>(`${BACKEND_URL}/notifications/status/${id}`, body).toPromise();
  }

  deleteNotification(id: number): Promise<NotificationResponse> {
    return this.http.delete<NotificationResponse>(`${BACKEND_URL}/notifications/delete/${id}`).toPromise();
  }

  createAllUsersNotifications(body: CreateNotificationsModel): Promise<CreateNotificationsResponse> {
    return this.http.post<CreateNotificationsResponse>(`${BACKEND_URL}/admin/notification/all`, body).toPromise();
  }

  createNotifications(id: number, body: CreateNotificationsModel): Promise<CreateNotificationsResponse> {
    return this.http.post<CreateNotificationsResponse>(`${BACKEND_URL}/admin/notification/all/${id}`, body).toPromise();
  }

  createTeacherNotifications(id: number, body: CreateNotificationsModel): Promise<CreateNotificationsResponse> {
    return this.http.post<CreateNotificationsResponse>(`${BACKEND_URL}/teacher-profile/notification/all/${id}`, body).toPromise();
  }

  userProfile(): Promise<UserResponse> {
    return this.http.get<UserResponse>(`${BACKEND_URL}/user-profile`).toPromise();
  }

  updateUserProfile(body: UserModel): Promise<UserResponse> {
    return this.http.patch<UserResponse>(`${BACKEND_URL}/user-profile/profile`, body).toPromise();

  }

  createReview(body: ReviewModel): Promise<ReviewResponse> {
    return this.http.post<ReviewResponse>(`${BACKEND_URL}/review/review/create`, body).toPromise();
  }

  GetReview(name: string): Promise<ReviewResponse> {
    return this.http.get<ReviewResponse>(`${BACKEND_URL}/review/review/${name}`).toPromise();
  }

  deleteReview(id: number): Promise<ReviewResponse> {
    return this.http.delete<ReviewResponse>(`${BACKEND_URL}/review/delete/${id}`).toPromise();
  }
  editReview(body: ReviewModel): Promise<ReviewResponse> {
    return this.http.patch<ReviewResponse>(`${BACKEND_URL}/review/edit`, body).toPromise();
  }

  courseReview(name: string): Promise<courseReviewResponse> {
    return this.http.get<courseReviewResponse>(`${BACKEND_URL}/review/all/${name}`).toPromise();
  }
  saveCurrentTime(body: UserProgressModel): Promise<GetUserProgressResponse> {
    return this.http.post<GetUserProgressResponse>(`${BACKEND_URL}/progress/saveCurrentTime`, body).toPromise();
  }

}
