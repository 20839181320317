import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CourseProgressRoutingModule } from './course-progress-routing.module';
import { CourseProgressComponent, UserNameDialogComponent } from './course-progress.component';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatMenuModule} from '@angular/material/menu';
import { MatButtonModule} from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    CourseProgressComponent,
    UserNameDialogComponent,
    
  ],
  imports: [
    CommonModule,
    CourseProgressRoutingModule,
    MatProgressSpinnerModule,
    FontAwesomeModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule
  ],
  exports: [
    CourseProgressComponent,
  ]
})
export class CourseProgressModule {}
